<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2023-01-17 20:11:04
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
  <div>
    <div class="sld_order_list">
      <MemberTitle :memberTitle="L['其他推荐']"></MemberTitle>
      <div class="container">
        <h3>{{ L["其他推荐"] }}</h3>
        <div class="sld_order_nav">
          <h3>{{ "基本信息" }}</h3>
          <el-table :data="tableData.data" border style="width: 100%">
            <el-table-column prop="planCode" label="采购单号" width="180" />
            <el-table-column prop="source" label="采购来源" width="180" />
            <el-table-column prop="type" label="采购类型" />
            <el-table-column prop="address" label="创建人" />
            <el-table-column prop="address" label="创建部门" />
            <el-table-column prop="createTime" label="创建日期" />
            <el-table-column prop="desc" label="备注" />
            <el-table-column prop="state" label="状态">
              <template #default="scope">
                <!--<el-button
                  size="small"
                  v-if="scope.row.state == 0"
                  @click="handleEdit(scope.$index, scope.row)"
                  >Edit</el-button
                >-->
                <el-tag
                  v-if="scope.row.state == 0"
                  @click="handleDelete(scope.$index, scope.row)"
                  :type="info"
                  class="mx-1"
                  effect="dark"
                >
                  待报价
                </el-tag>
                <el-tag
                  v-if="scope.row.state == 1"
                  @click="handleDelete(scope.$index, scope.row)"
                  :type="warning"
                  class="mx-1"
                  effect="dark"
                >
                  报价中
                </el-tag>
                <el-tag
                  v-if="scope.row.state == 2"
                  @click="handleDelete(scope.$index, scope.row)"
                  :type="success"
                  class="mx-1"
                  effect="dark"
                >
                  已报价
                </el-tag>

                <el-tag
                  v-if="scope.row.state == 3"
                  @click="handleDelete(scope.$index, scope.row)"
                  :type="warning"
                  class="mx-1"
                  effect="danger"
                >
                  已关闭
                </el-tag>
              </template>
            </el-table-column>
            <!--<el-table-column prop="address" label="操作">
              <template #default="scope">
                <el-button
                  link
                  type="primary"
                  size="small"
                  @click="toDetail(scope.planCode)"
                  >查看</el-button
                >
              </template>
            </el-table-column>-->
          </el-table>
        </div>

        <div class="sld_List">
          <h3 style="margin-top: 20px">{{ "采购商品列表" }}</h3>
          <el-table
            :data="tableData.goodsList"
            style="width: 100%"
            default-expand-all
            ref="multipleTableRef"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column type="expand" :prop="[needSku]">
              <template #default="props">
                <div style="width: 100%">
                  <p style="text-align: right">
                    价格有效期至{{ props.row.needSku.quoteTime }}
                  </p>
                  <div class="child">
                    <div>
                      <el-image
                        style="width: 100px; height: 100px"
                        :src="props.row.needSku.goodsMainImage"
                        :fit="fit"
                      />
                    </div>
                    <div class="goodsMassage">
                      <div class="operationContent">
                        <p>{{ props.row.needSku.skuName }}</p>
                        <div m="4" class="goodsContent">
                          <p m="t-0 b-2">
                            SKU: {{ props.row.needSku.skuCode }}
                          </p>
                          <p m="t-0 b-2">
                            价格:
                            <span style="color: red"
                              >￥{{ props.row.needSku.goodsPrice }}</span
                            >
                          </p>
                          <p v-if="supplierUserName" m="t-0 b-2">
                            供应商: {{ props.row.needSku.supplierUserName }}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <el-input-number
                            :min="props.row.needSku.minNum"
                            :max="props.row.needSku.goodsStock"
                            @change="handleChange"
                            size="small"
                          />
                        </div>
                        <p
                          style="
                            margin-top: 10px;
                            text-align: right;
                            color: blue;
                          "
                          @click="OtherRecommendations"
                        >
                          其他推荐>>
                        </p>
                      </div>
                    </div>
                  </div>
                  <!--<el-table :data="[props.row.needSku]" >
            <el-table-column label="Name" prop="name" />
            <el-table-column label="State" prop="state" />
            <el-table-column label="City" prop="city" />
            <el-table-column label="Address" prop="address" />
            <el-table-column label="Zip" prop="zip" />
          </el-table>-->
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="goodsName" label="商品名称" />
            <el-table-column prop="goodsSpec" label="规格" />
            <el-table-column prop="goodsMarque" label="型号" />
            <el-table-column prop="goodsUnit" label="计量单位" />
            <el-table-column prop="purchaseNum" label="采购数量" />
            <el-table-column prop="desc" label="备注" />
            <el-table-column prop="needDate" label="需求日期" />
            <el-table-column prop="state" label="状态">
              <template #default="scope">
                <el-tag
                  v-if="scope.row.state == 0"
                  @click="handleDelete(scope.$index, scope.row)"
                  :type="info"
                  class="mx-1"
                  effect="dark"
                >
                  待报价
                </el-tag>
                <el-tag
                  v-if="scope.row.state == 1"
                  @click="handleDelete(scope.$index, scope.row)"
                  :type="warning"
                  class="mx-1"
                  effect="dark"
                >
                  报价中
                </el-tag>
                <el-tag
                  v-if="scope.row.state == 2"
                  @click="handleDelete(scope.$index, scope.row)"
                  :type="success"
                  class="mx-1"
                  effect="dark"
                >
                  已报价
                </el-tag>

                <el-tag
                  v-if="scope.row.state == 3"
                  @click="handleDelete(scope.$index, scope.row)"
                  :type="warning"
                  class="mx-1"
                  effect="danger"
                >
                  已关闭
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
          <div class="showContent">
            <div
              style="
                display: flex;
                font-size: 14px;
                align-items: center;
                margin-right: 20px;
              "
            >
              <p>
                需求数量:<span style="color: red; margin-right: 20px">{{
                  tableData.needCount.totality
                }}</span
                >件
              </p>
              <p>
                已报价数量:<span style="color: red; margin-right: 20px">{{
                  tableData.needCount.toBeQuoted
                }}</span
                >件
              </p>
              <p>
                待报价数量:<span style="color: red; argin-right: 20px">{{
                  tableData.needCount.haveQuoted
                }}</span
                >件
              </p>
            </div>
            <div style="display: flex; align-items: center">
              <p style="margin-right: 20px; font-size: 16px">
                已报价金额:<span style="color: red">{{ AllMoney }}</span>
              </p>
              <el-button type="primary" @click="creatOrder">生成订单</el-button>
              <el-button @click="closeOrder">关闭订单</el-button>
            </div>
          </div>
          <!--<el-table
            :data="tableData.goodsList"
            style="width: 100%; margin-bottom: 20px"
            row-key="id"
            border
            default-expand-all
           :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            ref="multipleTableRef"
            @selection-change="handleSelectionChange"
          >
          <el-table-column type="selection" width="55" />
          <el-table-column prop="goodsName" label="商品名称"  />
            <el-table-column prop="goodsSpec" label="规格"  />
            <el-table-column prop="goodsMarque" label="型号"  />
            <el-table-column prop="goodsUnit" label="计量单位"  />
            <el-table-column prop="purchaseNum" label="采购数量"  />
            <el-table-column prop="desc" label="备注"  />
            <el-table-column prop="needDate" label="需求日期"  />
            <el-table-column prop="state" label="状态">
              <template #default="scope">
               
                <el-tag
                  v-if="scope.row.state == 0"
                  @click="handleDelete(scope.$index, scope.row)"
                  :type="info"
                  class="mx-1"
                  effect="dark"
                >
                  待报价
                </el-tag>
                <el-tag
                  v-if="scope.row.state == 1"
                  @click="handleDelete(scope.$index, scope.row)"
                  :type="warning"
                  class="mx-1"
                  effect="dark"
                >
                  报价中
                </el-tag>
                <el-tag
                  v-if="scope.row.state == 2"
                  @click="handleDelete(scope.$index, scope.row)"
                  :type="success"
                  class="mx-1"
                  effect="dark"
                >
                  已报价
                </el-tag>

                <el-tag
                  v-if="scope.row.state == 3"
                  @click="handleDelete(scope.$index, scope.row)"
                  :type="warning"
                  class="mx-1"
                  effect="danger"
                >
                  已关闭
                </el-tag>
              </template>
            </el-table-column>
          </el-table>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, getCurrentInstance, ref, onMounted } from "vue";
import {
  ElInput,
  ElMessage,
  ElMessageBox,
  ElTimeline,
  ElTimelineItem,
} from "element-plus";
// import { Search } from '@element-plus/icons-vue'
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiUrl } from "../../../utils/config";
import axios from "axios";
import SldCommonEmpty from "../../../components/SldCommonEmpty";
import MemberTitle from "../../../components/MemberTitle";
import download from "downloadjs";
export default {
  name: "order-list",
  components: {
    ElInput,
    ElTimeline,
    ElTimelineItem,
    SldCommonEmpty,
    MemberTitle,
  },
  setup() {
    // const balance = require("../../assets/buy/balance.png");
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const memberInfo = reactive({ data: store.state.memberInfo });
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const current_state = ref(""); //当前列表订单状态
    const evaluate_state = ref(0); //是否是待评价
    const fits = ["fill", "contain", "cover", "none", "scale-down"];
    const orderSn = ref(""); //订单id
    const multipleTableRef = ref();
    const AllMoney = ref(0);
    const pageData = reactive({
      pageNum: 1,
      pageSize: 10,
      total: 0,
    });
    const multipleSelection = ref([]);
    const tableData = reactive({ data: [], goodsList: [], needCount: {} });
    let param = reactive({
      memberId: store.state.memberInfo.memberId,
      planCode: "",
      source: "",
      prayName: "",
      startDate: "",
      endDate: "",
      resource: "",
      state: "",
      pageNum: 1,
      pageSize: 10,
    });
    const onSubmit = () => {
      console.log("submit!");
    };
    const toDetail = () => {
      router.push({
        path: "/member/order/orderReview",
        query: {
          orderSn: orderSn,
          type: 2,
        },
      });
    };
    const getOrderList = () => {
      let param = {
        memberId: store.state.memberInfo.memberId,
      };
      //proxy
      //  .$get("api/shopp/purchase/plan/page", param)
      //  .then((res) => {
      //    if (res.code == 200) {
      //      tableData.data = res.data.list;
      //      pageData.total = res.data.pagination.total;
      //    } else {
      //      ElMessage(res.msg);
      //    }
      //  })
      //  .catch(() => {
      //    //异常处理
      //  });
    };
    const getDetailData = () => {
      console.log(route.query);
      //proxy
      //  .$get("api/shopp/purchase/plan/detail", {
      //    //原接口：v3/business/front/orderInfo/detail
      //    planCode: route.query.planCode,
      //  })
      //  .then((res) => {
      //    if (res.code == 200) {
      //      tableData.data = [res.data];
      //      tableData.goodsList = res.data.needList;
      //      console.log(tableData);
      //    } else {
      //      ElMessage(res.msg);
      //    }
      //  })
      //  .catch(() => {
      //    //异常处理
      //  });
      proxy
        .$get("api/shopp/purchase/plan/needCount", {
          //原接口：v3/business/front/orderInfo/detail
        })
        .then((res) => {
          if (res.code == 200) {
            tableData.needCount = res.data;
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
    };
    const handleSelectionChange = (val) => {
      multipleSelection.value = val;
      if (multipleSelection) {
        let total = 0;

        for (const item of multipleSelection.value) {
          console.log(item.needSku);
          const { goodsPrice, goodsNum } = item.needSku;

          total += goodsPrice * goodsNum;
        }
        AllMoney = total;
        console.log(total);
      }
    };
    const OtherRecommendations=()=>{
      // router.push({
      //  path: "/member/order/OtherRecommendations",
      //  query: {
      //    orderSn: orderSn,
      //    type: 2,
      //  },
      //});
    }
    const getSelectGoods = (array1, array2) => {
      const result = [];

      for (let i = 0; i < array1.length; i++) {
        const searchString = array1[i];
        console.log(searchString);

        const foundObject = array2.find((obj) => obj.skuCode == searchString);

        //if (foundObject) {
        //	if (goodsList[i]?.skuCode != foundObject.skuCode) {
        //let freightCode = data.receiverAddressCode.split(",")[1]
        //console.log(freightCode);

        //if (freightCode) {
        //	if (freightCode == 6108) {
        //		foundObject.freightAmount = foundObject.freightToYl;
        //		//setGoodesList(goodsList)
        //	} else if (freightCode == 1509) {
        //		foundObject.freightAmount = foundObject.freightToWlcb;
        //		//setGoodesList(goodsList)
        //	} else {
        //		foundObject.freightAmount = foundObject.freightAmount;
        //		//setGoodesList(goodsList)
        //	}
        //}
        result.push(foundObject);
        console.log(result);

        //}

        //}
      }
      //setAllFreights(result)
      return result;
    };
    onMounted(() => {
      if (route.query.orderState) {
        current_state.value = route.query.orderState;
      } else {
        current_state.value = "";
      }
      if (route.query.evaluateState) {
        evaluate_state.value = route.query.evaluateState;
      } else {
        evaluate_state.value = "";
      }
      //  getTime();
      getDetailData();
      getOrderList();

      //  getCancelList();
      //  getAddressList();
    });
    //清空搜索订单
    //const clear = () => {
    //  orderSn.value = "";
    //  getOrderList();
    //};

    //const toKefu = (item) => {
    //  let chatInfo = {
    //    storeId: item.storeId,
    //    vendorAvatar: item.storeLogo,
    //    storeName: item.storeName,
    //    source: "从订单列表进入",
    //  };
    //  store.commit("saveChatBaseInfo", chatInfo);

    //  let newWin = router.resolve({
    //    path: "/service",
    //    query: {
    //      vid: item.storeId,
    //    },
    //  });

    //  window.open(newWin.href, "_blank");
    //};

    router.beforeEach((to, from, next) => {
      //  if (to.query.orderState) {
      //    current_state.value = to.query.orderState;
      //  } else {
      //    current_state.value = "";
      //  }
      //  if (to.query.evaluateState) {
      //    evaluate_state.value = to.query.evaluateState;
      //  } else {
      //    evaluate_state.value = "";
      //  }
      if (to.path == "/member/procurement/procuremmentPlanDetail") {
        //getOrderList();
        //getDetailData();
      }
      next();
    });
    return {
      L,
      getOrderList,
      onSubmit,
      param,
      tableData,
      toDetail,
      fits,
      multipleTableRef,
      multipleSelection,
      handleSelectionChange,
      AllMoney,
      OtherRecommendations
    };
  },
};
</script>
<style lang="scss">
@import "../../../style/orderList.scss";

.el-pager li.active {
  color: $colorMain;
  cursor: default;
  font-size: 16px;
}

.el-pager li:hover {
  color: $colorMain;
}

.el-select-dropdown__item.selected {
  color: $colorMain;
}

.sld_order_list {
  color: #000;
  .sld_List {
    color: #000;
  }
  ::v-deep .el-dialog__header {
    background-color: $colorMain;
    padding: 11px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ::v-deep .el-dialog__title {
      color: #fff;
      font-size: 14px;
    }
    ::v-deep .el-dialog__headerbtn {
      top: auto;
    }
    ::v-deep .el-dialog__headerbtn .el-dialog__close {
      color: #fff !important;
      font-size: 18px;
    }
  }
  ::v-deep .el-dialog {
    overflow: hidden;
    border-radius: 4px;
  }
  ::v-deep .el-dialog__footer {
    display: flex;
    // justify-content: center;
    align-items: center;
  }
  .aft_btn:hover {
    background-color: rgba($color: #f30213, $alpha: 0.2);
    color: #f30213;
  }
  .aft_btn:focus {
    background-color: #fff;
    color: #333333;
    border-color: #dcdfe6;
  }
  .dia_btn {
    .el-button {
      width: 134px;
      height: 36px;
    }
  }
  .downLoad_contract {
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #e8e8f1;
    border-radius: 5px;
    cursor: pointer;
  }
  .downLoad_contract:hover {
    border: 1px solid $colorMain;
  }
}
.container {
  .bootom-footer {
    text-align: right;
  }
  .bootom-footer button:first-child {
    margin-right: 10px;
  }
  // .el-input {
  //   width: 300px;
  // }
  .unit {
    font-size: 18px;
    margin-left: 10px;
  }
}
.demo-form-inline .el-input {
  --el-input-width: 220px;
}
.el-table thead {
  color: #5f5f60;
}
.el-table {
  color: #2b2b2c;
}

.child {
  width: 100%;
  display: flex;
  align-content: center;
  .goodsMassage {
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    width: 90%;
    align-items: center;
    .operationContent {
      width: 100%;
      line-height: 2.5;
      .goodsContent {
        display: flex;
        p {
          width: 30%;
          line-height: 2.5;
        }
      }
    }
  }
}
.showContent {
  margin-top: 20px;
  padding: 10px;
  background: rgb(250, 247, 247);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}
</style>